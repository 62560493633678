import React from "react";

import { appURLs } from "@/app/app-urls.mjs";
import * as SOON from "@/game-fortnite/constants/comingSoon.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import ComingSoon from "@/shared/ComingSoon.jsx";

const TITLE = ["common:navigation.fortniteMapsShop", "Fortnite Maps Shop"];
const DESCRIPTION = ["", "Shop for custom maps for Fortnite!"];

function ShopMaps() {
  return (
    <ComingSoon
      gameSymbol={GAME_SYMBOL_FORTNITE}
      pageTitle={TITLE}
      pageLinks={undefined}
      splashImage={`${appURLs.CDN}/blitz/ui/img/game-bg/fortnite-home-bg-centered.webp`}
      splashTitle={SOON.TITLE}
      splashDecription={SOON.DESCRIPTION}
      footerTitle={SOON.FOOTER_TITLE}
      footerDescription={SOON.FOOTER_DESC}
    />
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default ShopMaps;
